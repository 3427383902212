import React from 'react';
import { LineSeries, VerticalGridLines, LineSeriesPoint, AreaSeries, AreaSeriesPoint, HorizontalGridLines, XYPlot } from 'react-vis';
import { EColorBandType, IColorBand } from "./DataPageContent";
import 'react-vis/dist/style.css';

const VIS_TRANSPARENT = 0;
const VIS_OPAQUE = 1;

interface Props {
  data: LineSeriesPoint[];
  tickValues: number[];
  colorBands?: IColorBand;
  minValue: number;
  maxValue: number;
  historicForecastData?: LineSeriesPoint[];
  historicMeasuredData?: LineSeriesPoint[];
  width: number;
  height: number;
}

const getAreaSeriesPointColoring = (data: LineSeriesPoint[], boundY: number, type: EColorBandType, dataMinValue: number, dataMaxValue: number): AreaSeriesPoint[] => {
  const retArr: AreaSeriesPoint[] = [];

  // Check atleast two points in data:
  if (data.length < 2) {
    return retArr;
  }

  // set max/min depending on type:
  const maxValue = type === EColorBandType.GT ? dataMaxValue : dataMinValue;
  const minValue = type === EColorBandType.GT ? dataMinValue : dataMaxValue;

  // Add the first point to the array:
  retArr.push({
      x: data[0].x,
      y: data[0].y > boundY ? maxValue : minValue
  });

  // Add all intermediate points:
  // rebase the data to zero:
  const xZeroBasedDatadata = data.map(d => ({
    x: d.x - data[0].x,
    y: d.y
  }));
  
  for (let i = 0; i < xZeroBasedDatadata.length - 1; i++) {
    const p1 = xZeroBasedDatadata[i];
    const p2 = xZeroBasedDatadata[i+1];
    if ((p2.x - p1.x) === 0) continue;
    const m = (p2.y - p1.y) / (p2.x - p1.x);
    if (m === 0) continue;

    const x = (boundY - p1.y) / m + p1.x;
    if ((x >= p1.x && x <= p2.x) || (x >= p2.x && x <= p1.x)) {
      retArr.push({
          x: x + data[0].x,
          y: m < 0 ? maxValue : minValue,
      });
      retArr.push({
          x: x+1 + data[0].x, // TODO < mayy no +1
          y: !(m < 0) ? maxValue : minValue
      });
    }
  }

  // Add the last point:
  retArr.push({
    x: data[data.length-1].x,
    y: data[data.length-1].y > boundY ? maxValue : minValue
  });

  return retArr;
}

export const graphMargin = {
  left:5,
  right:0,
  top:5,
  bottom: 5
}

export const ParamGraph: React.FC<Props> = ({
  data,
  tickValues,
  colorBands,
  minValue,
  maxValue,
  historicForecastData,
  historicMeasuredData,
  width,
  height
}) => {
  
  const hasData = data.length > 0;
  let lineSeriesOpacity: number;
  if (!hasData) {
    lineSeriesOpacity = VIS_TRANSPARENT;
  }
  else {
    lineSeriesOpacity = VIS_OPAQUE;
  }


  const greenArea: AreaSeriesPoint[] = (hasData && colorBands) ? [ 
    { x: data[0].x, y: maxValue },
    { x: data[data.length - 1].x, y: maxValue },
  ] : [];
  const orangeArea = (hasData && colorBands) ? getAreaSeriesPointColoring(data, colorBands.amberStart, colorBands.type, minValue, maxValue) : [];
  const redArea = (hasData && colorBands) ? getAreaSeriesPointColoring(data, colorBands.redStart, colorBands.type, minValue, maxValue) : [];
  const brownArea = (hasData && colorBands) ? getAreaSeriesPointColoring(data, colorBands.brownStart, colorBands.type, minValue, maxValue) : [];

  const flaseData = tickValues.map((x): LineSeriesPoint => ({ x, y: maxValue }))
  if (flaseData.length > 0) {
    flaseData[0].y = minValue;
  }

  const vertLineData = [ minValue, 0.5*(minValue + maxValue), maxValue ];

  return (
    <XYPlot
      height={height}
      width={width}
      margin={graphMargin}
    >
      {hasData && colorBands && (
        <AreaSeries
          data={greenArea}
          color='#dfedd6'
          stroke='none'
        />
      )}
      {hasData && colorBands && (
        <AreaSeries
          data={orangeArea}
          color='#ffc957'
          stroke='none'
        />
      )}
      {hasData && colorBands && (
        <AreaSeries
          data={redArea}
          color='#ff6624'
          stroke='none'
        />
      )}
      {hasData && colorBands && (
        <AreaSeries
          data={brownArea}
          color='brown'
          stroke='none'
        />
      )}
      <VerticalGridLines 
        tickValues={tickValues}
        style={{
          stroke: 'grey'
        }}
      />
      <HorizontalGridLines
        tickValues={vertLineData}
        style={{
          stroke: 'grey'
        }}
      />
      <LineSeries
        data={data}
        color='blue'
        opacity={lineSeriesOpacity}
      />
      {
        historicForecastData && (
          <LineSeries
            data={historicForecastData}
            color='blue'
            strokeStyle='dashed'
            opacity={lineSeriesOpacity}
          />
        )
      }
      {
        historicMeasuredData && (
          <LineSeries
            data={historicMeasuredData}
            color='black'
            style={{
              strokeWidth: 1.5
            }}
            opacity={VIS_OPAQUE}
          />
        )
      }
      <LineSeries
        data={flaseData}
        color='red'
        opacity={VIS_TRANSPARENT}
      />
    </XYPlot>
  )
}