import { EUnitType } from "./data/IFetchForecast";

const MS_01SEC = 1000;
export const MS_01MIN = MS_01SEC * 60;
export const MS_01HR = MS_01MIN * 60;

export const MS_05SEC = MS_01SEC * 5;
export const MS_10MIN = MS_01MIN * 10;
export const MS_03HR = MS_01HR * 3;
export const MS_06HR = MS_01HR * 6;
export const MS_12HR = MS_01HR * 12;
export const MS_24HR = MS_01HR * 24;

export const MS_TIMEZONE_OFFSET = (new Date()).getTimezoneOffset() * MS_10MIN;

export const formatNumberByUnitType = (number: number, unit: EUnitType): string => {
    switch (unit) {
      case EUnitType.BearingDegrees:
        const rounded = Math.round(number);
        if (rounded < 10) return `00${rounded}`;
        if (rounded < 100) return `0${rounded}`;
        return rounded.toString();
      case EUnitType.DegreesCelcius: {
        const rounded = Math.round(number);
        let prefix = '';
        if (rounded > 0) prefix = '+';
        if (rounded < 0) prefix = '-';
        return `${prefix}${rounded}`;
      }
      case EUnitType.MBar:
        return Math.round(number).toString();
      case EUnitType.Mps: {
        const rounded = Math.round(number * 100) / 100;
        return rounded.toFixed(2);
      }
      case EUnitType.Knots:
      case EUnitType.Meters: {
        const rounded = Math.round(number * 10) / 10;
        return rounded.toFixed(1);
      }
      default:
        return number.toString();
    }
  }

  
export const getUnitAsString = (unit: EUnitType): string => {
    switch (unit) {
      case EUnitType.BearingDegrees:
        return '°';
      case EUnitType.DegreesCelcius: {
        return '°C';
      }
      case EUnitType.Knots: {
        return 'kts';
      }
      case EUnitType.Mps: {
        return 'mps';
      }
      case EUnitType.MBar:
        return 'mbar';
      case EUnitType.Meters: {
        return 'm';
      }
      default:
        return '?';
    }
  }
  