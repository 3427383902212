import React, { Fragment } from 'react';
import { IonChip, IonContent, IonHeader, IonItem, IonLabel, IonList, IonListHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './StationList.css';

import { DataContext } from '../data/DataContext';

export default class StationList extends React.Component {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  render() {
    const data = this.context;

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Station List</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonList>
            {Object.values(data.schema.RegionSet.Regions).map(region => (
              <Fragment key={region.Id}>
                <IonListHeader>
                  <IonLabel>{region.Label}</IonLabel>
                </IonListHeader>
                {Object.values(data.schema.ParameterSchema.Stations).filter(station => station.Station.Region === region.Id).map(station => (
                  <IonItem key={station.Station.Id} routerLink={`/stations/${station.Station.Id}`}>
                    <IonLabel>{station.Station.Label}</IonLabel>
                    {
                      this.context.isStationCached(station.Station.Id) && (
                        <IonChip slot="end" color="success">
                          <IonLabel color="success">Cached</IonLabel>       
                        </IonChip>
                      )                 
                    }
                  </IonItem>
                ))}
              </Fragment>
            ))}
          </IonList>
        </IonContent>
      </IonPage>
    );
  }
}
