import React from 'react';
import { LineSeries, FlexibleXYPlot, YAxis, RVTickFormat } from 'react-vis';
import 'react-vis/dist/style.css';

interface Props {
  hasData: boolean;
  yAxisLabelFormater: RVTickFormat;
  minValue: number;
  maxValue: number;
}

export const ParamGraphYAxis: React.FC<Props> = ({
  hasData,
  yAxisLabelFormater,
  minValue,
  maxValue
}) => {
  
  const tickVals = [ minValue, 0.5*(minValue + maxValue), maxValue ];
  
  return (
    <FlexibleXYPlot
      margin={{
        left:0,
        right:40,
        top:5,
        bottom: 5
      }}
    >
      <LineSeries
        data={[
          { x:0, y: minValue },
          { x:0, y: maxValue }
        ]}
        color='blue'
        opacity={0}
      />
      {/* Render labels ... */}
      {hasData && (
        <YAxis
          tickValues={tickVals}
          orientation='right'
          tickFormat={yAxisLabelFormater}
          style={{
            fontSize: '12px',
            fill: 'black'
          }}
          hideLine
          tickPadding={1}
        />
      )}
      {/* ... and Axis/Ticks separetley to style independently */}
      {hasData && (
        <YAxis
          // left={40}
          tickValues={tickVals}
          orientation='right'
          tickFormat={()=>''}
          style={{
            stroke: 'grey',
          }}
          tickPadding={-2}
        />
      )}
    </FlexibleXYPlot>
  )
}