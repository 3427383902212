import React, { useContext, useEffect, useState } from "react";
import { AppCacheContext } from "./AppCacheContext";
import { AuthContext } from "./AuthContext";
import { MS_10MIN, MS_24HR } from '../Utils';
import { EFetchLength, ICachedForecastDataLengths, IForecastData, IForecastDataCache, IGetForecastInput } from "../data/IFetchForecast";
import { AvailableParametersArray } from "../data/AvailableParameters";

const FORECAST_DATA_FETCH_INTERVAL = MS_10MIN;
export interface IForecastContext {
    forecastDataCache?: IForecastDataCache;
    isStationCached: (stationId: string) => boolean;
    immediateFetchStationForecast: (stationId: string) => Promise<ICachedForecastDataLengths | undefined>;
}

export const ForecastContext = React.createContext<IForecastContext>({} as any);
interface Props {
}

export const ForecastProvider: React.FC<Props> = ({ children }) => {
    const appCache = useContext(AppCacheContext);
    const authContext = useContext(AuthContext);
    const [forecastDataCache, setForecastDataCache] = useState<IForecastDataCache  | undefined>(undefined);
    const stationIdsToCache = [ 'Australia_Prelude' ];

    useEffect(() => {
        fetchAllStations();
        const interval = setInterval(() => {
            fetchAllStations();
          }, FORECAST_DATA_FETCH_INTERVAL);
        return () => clearInterval(interval);
    }, []);

    // liveData
    const cacheFetchForecast = async (input: IGetForecastInput, fromCache?: boolean): Promise<IForecastData | undefined> => {
        const { apiForecast,firstDate,forecastLength,parameterGroupId,stationId } = input;
        // TODO: 
        //  - Consider time 
        //  - Catch errors
        const dateFromEpocMs = firstDate.getTime() - MS_24HR;
        const dateFromEpochSeconds = Math.round(dateFromEpocMs / 1000);
        let dateToEpochSeconds: number;
        switch(forecastLength) {
            case EFetchLength.SevenDays:
                dateToEpochSeconds = dateFromEpochSeconds + 8 * 24 * 60 * 60;
                break;
            case EFetchLength.Measured:
            default:
                dateToEpochSeconds = dateFromEpochSeconds + 24 * 60 * 60;
                break;
        }

        let url: string;
        if (forecastLength === EFetchLength.Measured) {
            url = `https://www.shellmetnetglobal.com/api/HistoricalData/?q=SELECT FIRST(value) FROM "midi"."rp"."G.${stationId}.${parameterGroupId}" WHERE time >= ${dateFromEpochSeconds}s AND time < ${dateToEpochSeconds}s GROUP BY time(30m)&_=${dateFromEpocMs}`;
        }
        else {
            url = `https://www.shellmetnetglobal.com/api/HistoricalData/?q=SELECT FIRST(value) FROM "midi"."rp"."P.${stationId}.${apiForecast}.${parameterGroupId}" WHERE time >= ${dateFromEpochSeconds}s AND time < ${dateToEpochSeconds}s GROUP BY time(30m)&_=${dateFromEpocMs}`;
        }
        
        const cacheKey = `${stationId}-${forecastLength}-${parameterGroupId}`;

        if (fromCache) {
            const resp = await appCache.cacheOnly(cacheKey);
            if (resp) {
                return await resp.json() as IForecastData;
            }
            else {
                return undefined;
            }
        }
        else {
            const req = new Request(url, {
                headers: {
                    ...authContext.authHeader
                }
            });
            const resp = await appCache.networkOnly(cacheKey, req);
            return await resp.json() as IForecastData;
        }
    }

    const cacheFetchStation = async (date: Date, stationId: string, fromCache?: boolean): Promise<ICachedForecastDataLengths | undefined> => {
        const ret: ICachedForecastDataLengths = {
            lengths: {
              "7d": {
                params: {}
              },
              "measured": {
                params: {}
              }
            }
          };
      
          const params = AvailableParametersArray;
          for (let j = 0; j < params.length; j++) {
            const { paramId, apiForecast, measuredDataId } = params[j];
            const [ _7dData, _24prev ] = await Promise.all([
              cacheFetchForecast({
                apiForecast,
                firstDate: date,
                forecastLength: EFetchLength.SevenDays,
                parameterGroupId: paramId,
                stationId
            }, fromCache),
              cacheFetchForecast({
                apiForecast,
                firstDate: date,
                forecastLength: EFetchLength.Measured,
                parameterGroupId: measuredDataId ? measuredDataId : paramId,
                stationId
            }, fromCache),
            ]);
            if (_7dData && _24prev) {
              ret.lengths["7d"].params[paramId] = {
                data: _7dData
              };
              ret.lengths["measured"].params[paramId] = {
                data: _24prev
              };
            }
            else {
              return undefined;
            }
          }   
          return ret;     
    }

    const fetchStationForecastData = async (date: Date, stationId: string, onUpdate: (forecastData: ICachedForecastDataLengths) => void): Promise<ICachedForecastDataLengths | undefined> => {
        const fromCache = await cacheFetchStation(date, stationId, true);
        if (!fromCache) {
          return await cacheFetchStation(date, stationId, false);
        }
        else {
          cacheFetchStation(date, stationId, false)
            .then(value => {
              if (value) {
                onUpdate(value);
              }
            });
          return fromCache;
        }
    }

    const fetchSingleForecast = async (date: Date, stationId: string) => {
        const onUpdateStation = (forecastData?: ICachedForecastDataLengths) => {
            if (forecastData) {
                const data = forecastDataCache ? forecastDataCache : { stations: {} };
                data.stations[stationId] = forecastData;
                setForecastDataCache(data);
            }
        }
        const data = await fetchStationForecastData(date, stationId, onUpdateStation);
        onUpdateStation(data);
    }

    const fetchAllStations = async () => {
        const date = new Date();
        for (let i = 0; i < stationIdsToCache.length; i++) {
            const stationId = stationIdsToCache[i];
            await fetchSingleForecast(date, stationId);
        }
    }

    const isStationCached = (stationId: string): boolean => {
        if (!stationIdsToCache.includes(stationId)) return false;
        if (!forecastDataCache) return false;
        const stationData = forecastDataCache.stations[stationId];
        if (stationData) {
          return true;
        }
        return false;
    } 

    const immediateFetchStationForecast = async (stationId: string) => {
      return cacheFetchStation(new Date(), stationId, false);
    }
    
    const value: IForecastContext = {
        forecastDataCache,
        isStationCached,
        immediateFetchStationForecast
    }    

    return (
        <ForecastContext.Provider value={value}>
            {children}
        </ForecastContext.Provider>
    )
}