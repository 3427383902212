import React from 'react';
import { IonContent, IonHeader, IonItem, IonLabel, IonList, IonMenu, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, IonTitle, IonToolbar } from '@ionic/react';
import './StationTabsWrapper.css';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';
import  { AviationParams } from './pages/Aviation';
import  { MarineParams } from './pages/Marine';
import Pdfs from './pages/Pdfs';
import { DataContext } from './data/DataContext';
import { ICachedForecastDataLengths } from './data/IFetchForecast';
import Splash from './pages/Splash';
import DataPage from './pages/DataPage';

interface Props extends RouteComponentProps {
  signOut: () => void;
  showInstalPrompt?: () => void;
}
interface State {
  station: ICachedForecastDataLengths | undefined | "FetchError";
  stationId: string;
}

const getStationIdFromProps = (props: RouteComponentProps): string => {
  const { match } = props;
  return (match.params as any).stationId;
}

export default class StationTabsWrapper extends React.Component<Props, State> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  state: State = {
    station: undefined,
    stationId: ""
  }

  setStationOnState = async (stationId: string) => {
    let nextStation: ICachedForecastDataLengths | undefined | "FetchError";
    if (!this.context.isStationCached(stationId)) {
      this.setState({
        station: undefined,
        stationId: stationId
      })
      try {
        nextStation = await this.context.immediateFetchStationForecast(stationId);
      }
      catch {
        nextStation = "FetchError";
      }
    }
    else {
      nextStation = this.context.forecastDataCache.stations[stationId];
    }
    this.setState({
      station: nextStation,
      stationId
    });
  }

  componentDidMount() {    
    const stationId = getStationIdFromProps(this.props);    
    this.setStationOnState(stationId);
  }
  componentDidUpdate(prevProps: Props) {
    const stationId = getStationIdFromProps(this.props);
    if (getStationIdFromProps(prevProps) !== stationId) {
      this.setStationOnState(stationId);
    }
  } 
  

  render() {
    const {match} = this.props;
    const stationId = (match.params as any).stationId;
    const data = this.context;

    const station = data.liveData.Stations[stationId];

    if (!station) {
      return <Redirect to="/stations/Australia_Prelude" />;
    }

    return (
      <>
          {this.state.station === 'FetchError' && <Splash message={`Error fetching ${station.Station.Label}`} error/>}
          {this.state.station === undefined && <Splash message={`Fetching ${station.Station.Label}...`}/>}
          {
            (this.state.station !== 'FetchError' && this.state.station !== undefined) && (
              <>
              <IonMenu menuId="mainmenu" contentId='main' side='start'>
                <IonHeader>
                  <IonToolbar>
                    <IonTitle>ShellWeather.com</IonTitle>
                  </IonToolbar>
                </IonHeader>
                <IonContent>
                  <IonList>
                    <IonItem routerLink="/stations">Choose Station</IonItem>
                    <IonItem button onClick={this.props.signOut}>Sign Out</IonItem>
                    {
                      this.props.showInstalPrompt && (
                        <IonItem button onClick={this.props.showInstalPrompt}>Install App</IonItem>
                      )
                    }
                  </IonList>
                </IonContent>
              </IonMenu>
              <IonTabs>
                <IonRouterOutlet id="main">
                  <Route path="/stations/:stationId/aviation" exact={true}>
                    <DataPage station={this.state.station} stationId={this.state.stationId} parameters={AviationParams}/>
                  </Route>
                  <Route path="/stations/:stationId/marine" exact={true}>
                    <DataPage station={this.state.station} stationId={this.state.stationId}  parameters={MarineParams}/>
                  </Route>
                  <Route path="/stations/:stationId/pdfs" component={Pdfs} exact={true} />
                  <Route path="/stations/:stationId" render={() => <Redirect to={`/stations/${stationId}/aviation`} />} exact={true} />
                </IonRouterOutlet>
                <IonTabBar slot="bottom">
                  <IonTabButton tab="aviation" href={`/stations/${stationId}/aviation`} >
                    <IonLabel>Aviation</IonLabel>
                  </IonTabButton>
                  <IonTabButton tab="marine" href={`/stations/${stationId}/marine`} >
                    <IonLabel>Marine</IonLabel>
                  </IonTabButton>
                  {
                    (this.context.pdfs.filter(x => x.stationId === stationId).length > 0) && (
                      <IonTabButton tab="pdfs" href={`/stations/${stationId}/pdfs`} >
                        <IonLabel>PDFs</IonLabel>
                      </IonTabButton>
                    )
                  }
                  </IonTabBar>
              </IonTabs>   
              </>
            )
          }
      </>
    );
  }
}