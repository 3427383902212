export enum EUnitType {
    Knots,
    Mps,
    BearingDegrees,
    MBar,
    DegreesCelcius,
    Meters
  }

export enum EFetchLength {
    SevenDays = '7d',
    Measured = 'measured'
}

export enum EAPIForecast {
    Heading = 'Forecast_Heading',
    ThirtyMin = 'Forecast_30min'
}

export interface IGetForecastInput {
    stationId: string;
    parameterGroupId: string;
    forecastLength: EFetchLength;
    firstDate: Date;
    apiForecast: EAPIForecast;
}

export interface IForecastData {
    results: [{
        series: [{
            values: (string | number)[][];
        }];
    }];
}

export default interface IFetchForecast {
    getForecast: (input: IGetForecastInput) => Promise<IForecastData>
}

export interface ICachedForecastParams {
    [parameterGroupId: string]: {
      data: IForecastData;
    };
  }
export interface ICachedForecastDataLengths {
    lengths: {
        [key in EFetchLength]: {
            params: ICachedForecastParams;
        }
    }
  }
export interface IForecastDataCache {
    stations: {
      [stationId: string]: ICachedForecastDataLengths;
    }
}