import React from 'react';
import { IonAlert, IonButton, IonContent, IonLabel, IonPage, IonSpinner } from '@ionic/react';
import './Login.css';

interface Props {
  tryLogin(username: string, password: string, errorCallback?: (errorMessage: string) => void): Promise<void>;
  showInstalPrompt?: () => void;
}

interface State {
  username: string;
  password: string;
  loading: boolean;
  showModal: boolean;
  errorMessage?: string;
}

const ShellStyleConstants = {
  ColorRed: '#DD1D21',
  ColorYellow: '#FBCE07'
}

export default class Login extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
      showModal: false,
      errorMessage: undefined
    };
  }

  render() {
    return (
      <IonPage>
        <IonContent fullscreen slot='fixed'>
          <div style={{ backgroundColor: ShellStyleConstants.ColorYellow, width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
            <img alt='Shell Logo' src='./assets/shell_logo.png' style={{ width: '50%', maxWidth: '50vh', margin: 'auto', backgroundColor: 'white', marginTop: 50, marginBottom: 50, padding: 14 }}/>
            <div style={{ fontSize: 36, fontWeight: 'bold', color: ShellStyleConstants.ColorRed, marginLeft: 'auto', marginRight: 'auto'}}>
              ShellWeather.com
            </div>
            <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 40 }}>
              Please sign in with your <a href='https://www.shellmetnetglobal.com/' target='_blank' rel="noopener noreferrer">METNET Global</a> account.
            </div>
            <div style={{ marginLeft: '5%', marginRight: '5%', marginTop: 40 }}>
              <form>
                <input autoComplete='username' type='email' placeholder='Email' value={this.state.username} onChange={e => this.setState({username: e.target.value!})} style={{ width: '100%', marginBottom: 10, height: '2em'}}/>
                <input autoComplete='current-password' type='password' placeholder='Password' value={this.state.password} onChange={e => this.setState({password: e.target.value!})} style={{ width: '100%', height: '2em'}}/>
              </form>
            </div>
            <div style={{ marginLeft: '5%', marginRight: '5%', marginTop: 5, textAlign: 'center', marginBottom: 5 }}>
                {this.state.errorMessage ? <div style={{ color: ShellStyleConstants.ColorRed }}>{this.state.errorMessage}</div> : <div>&nbsp;</div>}
            </div>
            <div style={{ marginLeft: '5%', marginRight: '5%', marginTop: 20 }}>
              <IonButton onClick={this.tryLogin.bind(this)} expand="block" color="light" disabled={this.state.loading}>
                <IonLabel>
                  {this.state.loading ? <IonSpinner color='secondary'/> : 'Sign In'}
                </IonLabel>
              </IonButton>
            </div>
            <div style={{ marginLeft: '5%', marginRight: '5%', marginTop: 10, display: this.props.showInstalPrompt ? 'block' : 'none' }}>
              <IonButton onClick={() => {
                if (this.props.showInstalPrompt) {
                  this.props.showInstalPrompt();
                }
              }} expand="block" color="light" disabled={this.state.loading}>
                <IonLabel>
                  Install App
                </IonLabel>
              </IonButton>
            </div>
          </div>
          <IonAlert
            isOpen={this.state.showModal}
            onDidDismiss={() => this.setState({ showModal: false })}
            message={'Please enter your Shell METNET Global username and password.'}
            buttons={['OK']}
          />
        </IonContent>
      </IonPage>
    );
  }

  tryLogin() {
    this.setState({ errorMessage: undefined });
    if (this.state.username === '' || this.state.password === '') {
      this.setState({
        showModal: true
      })
    }
    else {
      this.setState({
        loading: true
      })
      this.props.tryLogin(this.state.username, this.state.password, (errorMessage => this.setState({ errorMessage }))).then(() => {
        this.setState({
          loading: false
        })
      });
    }
  }
}