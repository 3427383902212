import React from 'react';

interface Props {
    label: string;
    value: string;
    units: string;
}

export const DataPageRowLeft: React.FC<Props> = ({
    label,
    value,
    units
}) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginTop: 5 }}>
            <div style={{ fontSize: 10 }}>{label}</div>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'start'}}>
                <span style={{ fontSize: 30 }}>{value}</span>
                <span style={{ fontSize: 10, paddingLeft: 1, paddingTop: 4 }}>{units}</span>
            </div>
        </div>
    )
}