import React from 'react';
import { IonAlert, IonContent, IonLoading, IonPage } from '@ionic/react';
import './Login.css';
import { useHistory } from 'react-router';

interface Props {
  message: string;
  error?: true
}

const ShellStyleConstants = {
  ColorRed: '#DD1D21',
  ColorYellow: '#FBCE07'
}

const Splash: React.FC<Props> =  ({ message, error }) => {
  const history = useHistory();
  return (
    <IonPage>
      <IonLoading
          isOpen={!error}
          message={message}      
      />
      <IonAlert
        isOpen={!!error}
        onDidDismiss={() => history.push("/stations/Australia_Prelude")}
        message={message}
        buttons={['OK']}
      />
      <IonContent fullscreen slot='fixed'>
        <div style={{ backgroundColor: ShellStyleConstants.ColorYellow, width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
          <img alt='Shell Logo' src='./assets/shell_logo.png' style={{ width: '50%', maxWidth: '50vh', margin: 'auto', backgroundColor: 'white', marginTop: 50, marginBottom: 50, padding: 14 }}/>
          <div style={{ fontSize: 36, fontWeight: 'bold', color: ShellStyleConstants.ColorRed, marginLeft: 'auto', marginRight: 'auto'}}>
            ShellWeather.com
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Splash;