import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonMenu, IonMenuButton, IonPage, IonRouterLink, IonTitle, IonToolbar } from '@ionic/react';
import './Pdfs.css';

import { DataContext } from '../data/DataContext';
import { RouteComponentProps } from 'react-router-dom';
import { listOutline, downloadOutline } from 'ionicons/icons';
import { PdfViewer } from "../components/PdfViewer";
import IPdfData from '../data/IPDFData';

interface State {
  currentPdf?: IPdfData;
}

export default class Pdfs extends React.Component<RouteComponentProps, State> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  pdfMenuRef = React.createRef<HTMLIonMenuElement>();
  hiddenAnchorRef = React.createRef<HTMLAnchorElement>();

  state: State = {
    currentPdf: undefined,
  };

  componentDidMount() {
    this.setPdfFromQuery();
  }
  
  componentDidUpdate(prevProps: RouteComponentProps) {
    const { search } = this.props.location;
    const { search: lastSearch } = prevProps.location;
    if (search !== lastSearch) {
      this.setPdfFromQuery();
    }
  }  

  setPdfFromQuery = () => {
    const { search } = this.props.location;
    const { match } = this.props;
    const stationId = (match.params as any).stationId;
    const pdfLabel = decodeURI(search.replace("?pdf=", ""));
    const pdf = this.context.pdfs.filter(x => x.stationId === stationId).find(x => x.label === pdfLabel);
    if (pdf) {
      this.setState({
        currentPdf: pdf
      });
    }
    else {
      this.setState({
        currentPdf: undefined
      });
    }
    this.pdfMenuRef.current?.close();
  }

  render() {
    const {match} = this.props;
    const stationId = (match.params as any).stationId;
    const { liveData, pdfs } = this.context;
    const station = liveData.Stations[stationId];

    return (
      <IonPage>
        <IonMenu menuId="pdfmain" contentId='pdfcontent' side='end' ref={this.pdfMenuRef}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Select PDF</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonList>
              {pdfs.filter(x => x.stationId === stationId).map(pdfdata => {
                const isActive = (this.state.currentPdf && (this.state.currentPdf.label === pdfdata.label)) ? true : false;
                const color = isActive ? 'primary' : undefined;
                return (                  
                  <IonItem 
                    key={pdfdata.label}
                    disabled={!pdfdata.available} 
                    color={color}
                  >
                    <IonLabel className="ion-text-wrap">
                      <IonRouterLink 
                        routerLink={`/stations/${stationId}/pdfs?pdf=${pdfdata.label}`} 
                        color={isActive ? 'light' : 'primary'}
                      >
                        <div style={{ width: '100%' }}>
                          {pdfdata.label}
                        </div>
                      </IonRouterLink>
                    </IonLabel>
                    {
                      <IonButton
                        onClick={() => {
                          var reader = new FileReader();
                          reader.onload = (e) => {
                            console.log("In the onload")
                            var bdata = btoa(reader.result as string);
                            var datauri = 'data:application/pdf;base64,' + bdata;
                            const aElement = this.hiddenAnchorRef.current;
                            if (aElement) {
                              aElement.href = datauri;
                              aElement.download = pdfdata.label + ".pdf";
                              aElement.target = '_blank';
                              aElement.click();
                            }
                          };
                          reader.readAsBinaryString(pdfdata.blob);
                        }}
                      >
                        <IonIcon icon={downloadOutline} />
                      </IonButton>
                    }
                  </IonItem> 
                )
              })}
            </IonList>
            <a ref={this.hiddenAnchorRef} />
          </IonContent>
        </IonMenu>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton menu='mainmenu' />
            </IonButtons>
            <IonTitle
              slot="start"
              style={{ paddingLeft: 0 }}
            >
              {station.Station.Label}
            </IonTitle> 
            <IonButtons slot="end">
              <IonButton onClick={() => this.pdfMenuRef.current?.open()}>
                <IonIcon slot="icon-only" icon={listOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen scrollX id='pdfcontent'>
          <PdfViewer
            currentPdf={this.state.currentPdf}
            showPdfModal={() => this.pdfMenuRef.current?.open()}
          />   
        </IonContent>
      </IonPage>
    );
  }
}