import React from 'react';
import { LineSeries, VerticalGridLines, XAxis, LineSeriesPoint, XYPlot} from 'react-vis';
import 'react-vis/dist/style.css';
import { graphMargin } from './ParamGraph';

interface Props {
  tickValues: number[];
  labelValue: number[];
  width: number;
}

export const ParamGraphTimeAxis: React.FC<Props> = ({
  tickValues,
  labelValue,
  width
}) => {

  const monthTicks: Date[] = [];
  labelValue.forEach(x => {
    const mm = new Date(x);
    if (!(monthTicks.find(d => d.getDate() === mm.getDate() && d.getMonth() === mm.getMonth()))) {
      monthTicks.push(mm);
    }
  });

  const falseData = tickValues.map(ts => {
    const fd: LineSeriesPoint = {
      x: ts,
      y: 0
    }
    return fd;
  })
  
  return (
    <XYPlot
      width={width}
      height={45}
      margin={{ ...graphMargin, bottom: 0, top: 30 }}
    >
      <LineSeries
        data={falseData}
        color='blue'
        opacity={0}
      />
      <XAxis
        tickValues={monthTicks.map(x => x.valueOf())}
        tickFormat={t => {
          const d = new Date(t);
          const DD = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate().toString();
          const trueMM = d.getMonth() + 1;
          const MM = trueMM < 10 ? `0${trueMM}` : trueMM.toString();
          return`${DD}/${MM}`;
        }}
        style={{
          fontSize: '10px',
          fill: 'black'
        }}
        top={0}
        hideLine 
        orientation='top'
      />
      <XAxis
        tickValues={labelValue}
        tickFormat={t => {
          const d = new Date(t);
          const hh = d.getHours() < 10 ? `0${d.getHours()}` : d.getHours().toString();
          // const mm = d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes().toString();

          return `${hh}`;//:${mm}`;
        }}
        top={15}
        hideLine 
        orientation='top'
        style={{
          fontSize: '14px',
          fill: 'black'
        }}
      />
      <VerticalGridLines
        marginTop={35}
        tickValues={labelValue}
        height={10} 
        style={{
          stroke: 'grey',
        }}
      />
      <VerticalGridLines
        marginTop={40}
        tickValues={tickValues.filter(x => !labelValue.includes(x) )}
        height={5} 
        style={{
          stroke: 'grey',
        }}
      />
    </XYPlot>
  )
}