import React, { Fragment, useContext, useEffect, useState } from "react";
import { AppCacheContext } from "./AppCacheContext";
import ISchema from '../data/ISchema';
import { IonLoading } from "@ionic/react";
import Login from "../pages/Login";

export interface IAuthHeader {
    Authorization: string;
  }
export interface IAuthContext {
    authHeader: IAuthHeader;
    schema: ISchema;
    signOut: () => void;
}

export const AuthContext = React.createContext<IAuthContext>({} as any);
interface Props {
    showInstalPrompt?: () => void;
}

export const AuthProvider: React.FC<Props> = ({ children, showInstalPrompt }) => {
    const appCache = useContext(AppCacheContext);
    const [authHeader, setAuthHeader] = useState<IAuthHeader | undefined>(undefined);
    const [schema, setSchema] = useState<ISchema | undefined>(undefined);

    useEffect(() => {
        checkAuth();
    }, []);

    const resetAuth = () => {
        clearAuthTokenInStorage();
        setAuthHeader(undefined);
        setSchema(undefined);
        appCache.cacheReset();
    }

    const checkAuth = async (errorCallback?: (errorMessage: string) => void) => {
        const authHeader = getAuthHeaderFromStorage();
        if (authHeader) {
            setAuthHeader(authHeader);
        }
        else {
            resetAuth();
            return;
        }
    
        const handleFetchResponse = async (response: Response) => {
          if (response.status !== 200) {
              resetAuth();
              if (errorCallback) {
                  errorCallback("Sign in failed. Please confirm your username and password and try again.");
              }
          }
          else {
            const schema = await response.json() as ISchema;
            setSchema(schema);
          }
        }

        const handleUpdateResponse = async (promise: Promise<Response>) => {
            try {
                const response = await promise;
                handleFetchResponse(response);
            }
            catch (e) {
                // silently ignore error
            }
        }
    
        try {
          const req = new Request('https://www.shellmetnetglobal.com/api/Schema/Get', {
            headers: {
              ...authHeader,
            }
          });
          const payload = await appCache.cacheFetch("schema", req, handleUpdateResponse);
          handleFetchResponse(payload.response);
        } 
        catch (error) {
            resetAuth();
            if (errorCallback) {
                errorCallback("An unknown error has occured.");
            }
        }
    }

    const tryLogin = async (username: string, password: string, errorCallback?: (errorMessage: string) => void) => {
        setAuthTokenInStorage(username, password);
        await checkAuth(errorCallback);
    }
    
    // Auth headers:
    const getAuthHeaderFromStorage = (): IAuthHeader | undefined => {
        const storageToken = window.localStorage.getItem('token');
        if (storageToken) {
            return {
                'Authorization': 'Basic ' + storageToken
            }
        }
        return undefined;
    }
    const setAuthTokenInStorage = (username: string, password: string) => {    
        window.localStorage.setItem('token', btoa(username + ":" + password));
    }
    const clearAuthTokenInStorage = () => {
        window.localStorage.removeItem('token');
    }

    if (authHeader === undefined || schema === undefined) {
        return (
            <Fragment>
                <IonLoading
                    isOpen={authHeader !== undefined && !schema}
                    message={'Logging in...'}
                />
                <Login tryLogin={tryLogin} showInstalPrompt={showInstalPrompt}/>
            </Fragment>
        );
    }
    
    const value: IAuthContext = {
        authHeader,
        schema,
        signOut: () => {
            resetAuth();
        }
    }    
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}