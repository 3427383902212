import { AvailableParameters } from '../data/AvailableParameters';
import { EColorBandType, IPageParameters } from '../components/DataPageContent';

export const MarineParams: IPageParameters[] = [
  { groupId: "Heading_deg", ...AvailableParameters["Heading_deg"], colorBands: undefined },
  { groupId: "WindSpeed_kts_10m", ...AvailableParameters["WindSpeed_kts_10m"], colorBands: { amberStart: 15, redStart: 20, brownStart: 25, type: EColorBandType.GT } },
  { groupId: "GustSpeed_kts_10m", ...AvailableParameters["GustSpeed_kts_10m"], colorBands: { amberStart: 15, redStart: 20, brownStart: 25, type: EColorBandType.GT } },
  { groupId: "Total_CurrentDirection_Surface_deg_Mean_BCM", ...AvailableParameters["Total_CurrentDirection_Surface_deg_Mean_BCM"], colorBands: undefined },
  { groupId: "Total_CurrentSpeed_Surface_mps_Mean_BCM", ...AvailableParameters["Total_CurrentSpeed_Surface_mps_Mean_BCM"], colorBands: undefined },
  { groupId: "Hs_m", ...AvailableParameters["Hs_m"], colorBands: { amberStart: 1.5, redStart: 2.0, brownStart: 2.5, type: EColorBandType.GT } },
];