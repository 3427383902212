import React, { useState } from 'react';
const ItemNameShowForecastHistory = 'showForecastHistory';

export interface ILocalStorageContext {
    showForecastHistory: {
        value: boolean;
        set: (value: boolean) => void;
    }
}
export const LocalStorageContext = React.createContext<ILocalStorageContext>({} as any);

const useLocalStorage = <T,>(key: string, initialValue: T): [T, (value:T) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) as unknown as T : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  
  const setValue = (value: T) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(error);
    }
  };
  
  return [storedValue, setValue];
}

export const LocalStorageProvider: React.FC = ({ children }) => {
    const [showForecastHistory, setShowForecastHistory] = useLocalStorage<boolean>(ItemNameShowForecastHistory, false);
    const value: ILocalStorageContext = {
      showForecastHistory: {
        value: showForecastHistory,
        set: setShowForecastHistory
      }
    }
    return (
        <LocalStorageContext.Provider value={value}>
          {children}
        </LocalStorageContext.Provider>
    )
}