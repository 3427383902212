import React from "react";
const CACHE_NAME = "AppCache";

export interface IAppCacheFetchPayload {
    response: Response;
    fromCache: boolean;
}
export interface IAppCacheContext {
    cacheOnly: (cacheKey: string) => Promise<undefined | Response>;
    networkOnly: (cacheKey: string, request: Request) => Promise<Response>;
    cacheFetch: (cacheKey: string, request: Request, handleUpdateFromNetwork: (updateResponse: Promise<Response>) => void) => Promise<IAppCacheFetchPayload>;
    cacheReset: () => Promise<void>;
}

export const AppCacheContext = React.createContext<IAppCacheContext>({} as any);
interface Props {}

export const AppCacheProvider: React.FC<Props> = ({ children }) => {

    const cacheOnly = async (cacheKey: string): Promise<Response | undefined> => {
        const cache = await caches.open(CACHE_NAME);
        const match = await cache.match(cacheKey);
        if (match) {
            return match;
        }
        else {
            return undefined;
        }
    }

    const networkOnly = async (cacheKey: string, request: Request) => {
        const cache = await caches.open(CACHE_NAME);    
        const response = await fetch(request);
        if (response.status === 200) {
            cache.put(cacheKey, response.clone());
        }
        return response;
    }

    const cacheFetch = async (cacheKey: string, request: Request, handleUpdateFromNetwork: (updateResponse: Promise<Response>) => void): Promise<IAppCacheFetchPayload> => {

        const cache = await caches.open(CACHE_NAME);    
    
        const cachedResponse = await cache.match(cacheKey);

        const fetchPromise = async () => {
            const response = await fetch(request);
            if (response.status === 200) {
                cache.put(cacheKey, response.clone());
            }
            return response;
        }
    
        if (cachedResponse) {
            handleUpdateFromNetwork(fetchPromise());
            return {
                response: cachedResponse,
                fromCache: true
            };
        }
        else {
            return {
                response: await fetchPromise(),
                fromCache: false
            }
        }
    }

    const cacheReset = async () => {
        const cache = await caches.open(CACHE_NAME);    
        const keys = await cache.keys();
        for (let i = 0; i < keys.length; i++) {
            await cache.delete(keys[i])
        }
    }

    const value: IAppCacheContext = {
        cacheFetch,
        cacheOnly,
        cacheReset,
        networkOnly
    }
    return (
        <AppCacheContext.Provider value={value}>
            {children}
        </AppCacheContext.Provider>
    )
}