import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import StationTabsWraper from './StationTabsWrapper';
import StationList from './pages/StationList';
import { AuthContext } from './contexts/AuthContext';
import { DataProvider } from "./contexts/DataContext";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

interface Props {
  showInstalPrompt?: () => void;
}

interface State {
  stationIdsToCache: string[];
}

export class ProtectedApp extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  render() {
    return (
      <DataProvider>
        <IonReactRouter>
          <IonRouterOutlet>
            <Route path="/stations" component={StationList} exact={true} />
            <Route path="/stations/:stationId" render={(props) => <StationTabsWraper {...props} signOut={this.context.signOut} showInstalPrompt={this.props.showInstalPrompt} />} />
            <Route path="/" render={() => <Redirect to="/stations/Australia_Prelude" />} exact={true} />
          </IonRouterOutlet>
        </IonReactRouter>
      </DataProvider>
    );
  }
}