import React, { useEffect, useState } from 'react';

interface Props {
}

export const DataPageRowLeftDateTime: React.FC<Props> = () => {
    const [date,setDate] = useState(new Date());
    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
          }, 1000);
        return () => clearInterval(timer);
    }, []);

    const dtfOptions = {
        month: 'short',
        day: '2-digit',
        hour: 'numeric', minute: 'numeric',
        hour12: false,
    }

    const dateStringTop = new Intl.DateTimeFormat('en', dtfOptions).format(date);
    const timezoneOffsetHrs = date.getTimezoneOffset() / -60;
    const tzOffsetString = `UTC ${timezoneOffsetHrs < 0 ? '' : '+'}${timezoneOffsetHrs}`;

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 5, marginTop: 5, alignItems: 'center' }}>
            <div style={{ fontSize: 14 }}>{dateStringTop}</div>
            <div style={{ fontSize: 14, paddingTop: 5 }}>{tzOffsetString}</div>
        </div>
    )
}