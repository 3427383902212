import React, { Fragment } from 'react';
import { IonButtons, IonChip, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonSpinner, IonTitle, IonToggle, IonToolbar } from '@ionic/react';
import { DataContext } from '../data/DataContext';
import { ParamGraph } from "../components/ParamGraph";
import { ParamGraphTimeAxis } from "../components/ParamGraphTimeAxis";
import { ParamGraphYAxis } from "../components/ParamGraphYAxis";
import { DataPageTableRow } from "../components/DataPageTableRow";
import { LiveDataRowLeft } from "../components/LiveDataRowLeft";
import { DataPageRowLeftDateTime } from "../components/DataPageRowLeftDateTime";
import { EFetchLength, ICachedForecastDataLengths, IForecastData } from '../data/IFetchForecast';
import { LineSeriesPoint } from 'react-vis';
import { formatNumberByUnitType, MS_01HR, MS_03HR, MS_06HR, MS_12HR, MS_24HR, MS_TIMEZONE_OFFSET } from "../Utils";
import { EAxisLabelType, IParameter } from '../data/AvailableParameters';
import { barChartOutline } from "ionicons/icons";

export interface IPageParameters extends IParameter {  
  groupId: string;
  colorBands?: IColorBand;
}
interface Props {
  station: ICachedForecastDataLengths;
  stationId: string;
  parameters: IPageParameters[];
  forecastLength: EForecastLength;
};

export enum EForecastLength {
  TwentyFourHrs = '24h',
  ThreeDays = '3d',
  SevenDays = '7d'
}

interface State {
  innerWidth: number;
}

export interface IDataPageForecastDataParam {
  data: IDataPageParameterForecastData[];
  measuredForecastData: IDataPageParameterForecastData[];
}
interface IDataPageForecastData {
  [parameterGroupId: string]: IDataPageForecastDataParam;
}
interface IDataPageParameterForecastData {
  timestamp: number;
  value: number;
}
export enum EColorBandType {
  LT,
  GT
}
export interface IColorBand {
  amberStart: number;
  redStart: number;
  brownStart: number;
  type: EColorBandType;
}


export class DataPageContent extends React.Component<Props, State> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;
  mainContnetRef = React.createRef<HTMLIonContentElement>();

  state: State = {
    innerWidth: 0
  }

  componentDidMount() {    
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = async () => {
    let innerWidth = window.innerWidth;
    if (this.mainContnetRef.current) {
      const scrollElem = await this.mainContnetRef.current.getScrollElement();
      if (scrollElem) {
        innerWidth = scrollElem.clientWidth;
      }
    }
    this.setState({ innerWidth });
  }

  getCurrentForecastData = (stationData: ICachedForecastDataLengths): IDataPageForecastData => {
    const ret: IDataPageForecastData = {};

    const convertForecastDataToPageData = (data: IForecastData): IDataPageParameterForecastData[] => {
      const pData: IDataPageParameterForecastData[] = data.results[0].series[0].values.map(x => {
        // parse date (ios does not parse the API date string so parse manually):
        // format from API : 2020-09-24 12:00:00Z
        const [ YYYY, MM, DD, hh, mm, ss ] = (x[0] as string).split(/[- : Z]/).map(x => parseInt(x));
        const timestamp = Date.UTC(YYYY, MM - 1, DD, hh, mm, ss).valueOf();
        return {
          timestamp: timestamp,
          value: x[1] as number
        }
      });
      return pData;
    }

    const params = this.props.parameters;
    params.forEach(p => {
      const { groupId } = p;
      const pRawData = stationData.lengths[EFetchLength.SevenDays].params[p.groupId];
      const pRawDataMeas = stationData.lengths[EFetchLength.Measured].params[p.groupId];
      if (pRawData && pRawDataMeas) {
        ret[groupId] = {
          data: convertForecastDataToPageData(pRawData.data),
          measuredForecastData: convertForecastDataToPageData(pRawDataMeas.data),
        };
      }
    });
      
    return ret;
  }
    
  getTickValueTimestamps = (firstTs: number, lastTs: number) => {
    const roundTsUpTo = (ts: number, ms: number) => ts - (ts % ms) + ms;

    const offsetMs = getTickOffsetMs(this.props.forecastLength, this.context.cache.showForecastHistory.value);
    const minTimestamp = roundTsUpTo(firstTs - MS_TIMEZONE_OFFSET, offsetMs) + MS_TIMEZONE_OFFSET;
    const tickValues: number[] = [];    
    tickValues.push(firstTs);
    for (let ts = minTimestamp; ts < lastTs; ts += offsetMs) {
      tickValues.push(ts);
    } 
    tickValues.push(lastTs);
    return tickValues;
  } 

  
  
  

  render() {
    const data = this.context;
    const { stationId, station: stationFc } = this.props;
    const station = data.liveData.Stations[stationId];

    const forecastData = this.getCurrentForecastData(stationFc);

    const date = new Date();
    const roundTsDownToMs = (ts: number, ms: number) => ts - (ts % ms);
    const offset = getTickOffsetMs(this.props.forecastLength, this.context.cache.showForecastHistory.value);
    const forecastMinTs = roundTsDownToMs(date.valueOf(), offset);
    let forecastMaxTs: number;
    switch (this.props.forecastLength) {
      case EForecastLength.SevenDays:
        forecastMaxTs = forecastMinTs + MS_24HR * 7;
        break;
      case EForecastLength.ThreeDays:
        forecastMaxTs = forecastMinTs + MS_24HR * 3;
        break;
      default:
      case EForecastLength.TwentyFourHrs:
        forecastMaxTs = forecastMinTs + MS_24HR;
        break;
    }
    const historicMinTs = forecastMinTs - MS_24HR;

    const tickValues = this.getTickValueTimestamps(this.context.cache.showForecastHistory.value ? historicMinTs : forecastMinTs, forecastMaxTs);
    const labelValue = filterTickValuesForAxisLabels(tickValues, this.props.forecastLength);

    return (
      <Fragment>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton menu='mainmenu' />
            </IonButtons>
            <IonTitle
              slot="start"
              style={{ paddingLeft: 0, paddingRight: 0 }}
            >
              {station.Station.Label}
            </IonTitle>  
            {
              this.context.isStationCached(this.props.stationId) && (
                <IonChip slot="start" color="success">
                  <IonLabel color="success">Cached</IonLabel>       
                </IonChip>
              )                  
            } 
            <IonItem slot="end">
              <IonLabel style={{ fontSize: 14 }}>Meas</IonLabel>
              <IonIcon icon={barChartOutline} style={{ fontSize: 14, paddingLeft: 5 }}/>
              <IonToggle checked={this.context.cache.showForecastHistory.value} 
                onIonChange={e => this.context.cache.showForecastHistory.set(e.detail.checked)} 
              />
            </IonItem> 
          </IonToolbar>
        </IonHeader>
        <IonHeader>
          <IonToolbar >
            <table>
              <tbody>
                <DataPageTableRow 
                  width={this.state.innerWidth}
                  height={45}
                  leftColumnComponent={<DataPageRowLeftDateTime />}
                  middleColumnComponent={(innerWidth, innerHeight) => <ParamGraphTimeAxis
                    tickValues={tickValues}
                    labelValue={labelValue}
                    width={innerWidth}
                  />}
                />
              </tbody>
            </table>
          </IonToolbar>
        </IonHeader>
        <IonContent ref={this.mainContnetRef}>
          <table>
            <tbody>
              {this.props.parameters.map((p, idx) => {
                const fcData = forecastData[p.groupId] ? forecastData[p.groupId] : undefined;   
                const dataForChart: LineSeriesPoint[] = [];
                if (fcData) {
                  fcData.data.forEach(x => {
                    if (x.timestamp >= forecastMinTs && x.timestamp <= forecastMaxTs) {
                      dataForChart.push({
                        x: x.timestamp,
                        y: x.value
                      })
                    }
                  });
                }     

                const historicMeasuredDataForGraph: LineSeriesPoint[] = [];
                if (fcData && this.context.cache.showForecastHistory.value) {
                  fcData.measuredForecastData.forEach(x => {
                    if (x.timestamp >= historicMinTs && x.timestamp <= forecastMinTs) {
                      historicMeasuredDataForGraph.push({
                        x: x.timestamp,
                        y: x.value
                      })
                    }
                  });
                } 

                const historicForecastDataForGraph: LineSeriesPoint[] = [];
                if (fcData && dataForChart.length > 0 && this.context.cache.showForecastHistory.value) {
                  fcData.data.forEach(x => {
                    if (x.timestamp >= historicMinTs && x.timestamp <= forecastMinTs) {
                      historicForecastDataForGraph.push({
                        x: x.timestamp,
                        y: x.value
                      })
                    }
                  });
                } 

                let min: number;
                let max: number;
                if (p.axisLabel.__typename === EAxisLabelType.IAxisLabelFixed) {
                  min = p.axisLabel.min;
                  max = p.axisLabel.max;
                }
                else {
                  const yOnly = dataForChart.map(x => x.y);
                  const yOnlyHistoric = historicForecastDataForGraph ? historicForecastDataForGraph.map(x => x.y) : [];
                  const yOnlyMeasured = historicMeasuredDataForGraph ? historicMeasuredDataForGraph.map(x => x.y) : [];

                  if (yOnly.length === 0 && yOnlyHistoric.length === 0 && yOnlyMeasured.length === 0) {
                    min = 0;
                    max = 0;
                  }
                  else {
                    min = Math.min(...yOnly, ...yOnlyHistoric, ...yOnlyMeasured);
                    max = Math.max(...yOnly, ...yOnlyHistoric, ...yOnlyMeasured);
                    min = Math.floor(min / p.axisLabel.roundTo) * p.axisLabel.roundTo;
                    max = Math.ceil(max / p.axisLabel.roundTo) * p.axisLabel.roundTo;
                    if (min === max) {
                      min -= p.axisLabel.roundTo / 2;
                      max += p.axisLabel.roundTo / 2;
                    }
                  }
                }   

                return (
                  <DataPageTableRow
                    width={this.state.innerWidth}
                    key={p.groupId}
                    leftColumnComponent={<LiveDataRowLeft 
                      stationId={stationId}
                      parameter={p}
                    />}
                    middleColumnComponent={(innerWidth, innerHeight) =>
                      fcData ? (
                        <ParamGraph
                          height={innerHeight}
                          width={innerWidth}
                          tickValues={tickValues}
                          data={dataForChart}
                          // hasData={hasData}
                          colorBands={p.colorBands}
                          minValue={min}
                          maxValue={max}
                          historicMeasuredData={historicMeasuredDataForGraph}
                          historicForecastData={historicForecastDataForGraph}
                        />
                      ) : (
                        <IonSpinner />
                      )
                    }
                    rightColumnComponent={
                      fcData && (
                        <ParamGraphYAxis
                          hasData={dataForChart.length > 0 || historicMeasuredDataForGraph.length > 0}
                          yAxisLabelFormater={(t: number) => formatNumberByUnitType(t, p.unit)}
                          minValue={min}
                          maxValue={max}
                        />
                      )
                    }                    
                  />
                );
              })}
            </tbody>
          </table>
        </IonContent>
      </Fragment>
    );
  }
}

const filterTickValuesForAxisLabels = (timestamps: number[], forecastLength: EForecastLength): number[] => {
  let ret: number[] = [];
  if (timestamps.length < 2) return [];

  switch (forecastLength) {
    case EForecastLength.ThreeDays: {
      ret = timestamps.filter(ts => (ts - MS_TIMEZONE_OFFSET) % MS_12HR === 0);
      break;
    }
    case EForecastLength.SevenDays: {
      ret = timestamps.filter(ts => (ts - MS_TIMEZONE_OFFSET) % MS_24HR === 0);
      break;
    }
    default:
    case EForecastLength.TwentyFourHrs: {
      ret = timestamps.filter(ts => (ts - MS_TIMEZONE_OFFSET) % MS_06HR === 0);
      break;
    }
  }
  
  return ret;
}

const getTickOffsetMs = (forecastLength: EForecastLength, showHistoricData: boolean) => {
  let offsetMs: number;
  switch (forecastLength) {
    case EForecastLength.ThreeDays:
      offsetMs = showHistoricData ? MS_12HR : MS_06HR;
      break;
    case EForecastLength.SevenDays:
      offsetMs = MS_12HR;
      break;
    case EForecastLength.TwentyFourHrs:
    default:
      offsetMs = showHistoricData ? MS_03HR : MS_01HR;
      break;
  }
  return offsetMs;
}