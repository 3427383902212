import React, { useContext, useEffect, useState } from "react";
import { AppCacheContext } from "./AppCacheContext";
import { AuthContext } from "./AuthContext";
import ILiveData from "../data/ILiveData";
import { MS_01MIN } from '../Utils';

const LIVE_DATA_FETCH_INTERVAL = MS_01MIN;
export interface ILiveDataContext {
    liveData?: ILiveData;
}

export const LiveDataContext = React.createContext<ILiveDataContext>({} as any);
interface Props {
}

export const LiveDataProvider: React.FC<Props> = ({ children }) => {
    const appCache = useContext(AppCacheContext);
    const authContext = useContext(AuthContext);
    const [liveData, setLiveData] = useState<ILiveData | undefined>(undefined);

    useEffect(() => {
        const fetchLiveData = async () => {
            const setLiveDataFromResponse = async (response: Response) => {
                const liveData = await response.json() as ILiveData;
                setLiveData(liveData);
            }
    
            const req = new Request('https://www.shellmetnetglobal.com/api/LiveData', {
                headers: {
                    ...authContext.authHeader
                }
            });

            const handleUpdateResponse = async (promise: Promise<Response>) => setLiveDataFromResponse(await promise);
    
            const cacheFetchPayload = await appCache.cacheFetch("liveData", req, handleUpdateResponse);
            setLiveDataFromResponse(cacheFetchPayload.response);
        }
        fetchLiveData();
        const interval = setInterval(() => {
            fetchLiveData();
          }, LIVE_DATA_FETCH_INTERVAL);
        return () => clearInterval(interval);
    }, []);    
    
    const value: ILiveDataContext = {
        liveData
    }    

    return (
        <LiveDataContext.Provider value={value}>
            {children}
        </LiveDataContext.Provider>
    )
}