import React, { ReactNode } from 'react';

interface Props {
  width: number;
  height?: number;
  leftColumnComponent?: ReactNode;
  middleColumnComponent?: (innerWidth:number, innerHeight: number) => ReactNode;
  rightColumnComponent?: ReactNode;
}

const RIGHT_COLUMN_WIDTH = 40;
const LEFT_COLUMN_WIDTH = 100;
const DEFAULT_HEIGHT = 140;
const CHART_TOP_PADDING = 5;

export const DataPageTableRow: React.FC<Props> = ({
  width,
  height = DEFAULT_HEIGHT,
  leftColumnComponent,
  middleColumnComponent,
  rightColumnComponent
}) => {
  const middleWidth = width - LEFT_COLUMN_WIDTH - RIGHT_COLUMN_WIDTH < 0 ? 0 : width - LEFT_COLUMN_WIDTH - RIGHT_COLUMN_WIDTH;
  const middleHeight = height - CHART_TOP_PADDING < 0 ? 0 : height - CHART_TOP_PADDING;
    return (
      <tr style={{ maxHeight: height, minHeight: height }}>
        <td style={{ width: LEFT_COLUMN_WIDTH, display: 'block' }}>
          {leftColumnComponent}
        </td>
        <td style={{ width: middleWidth, height }}>
          <div  style={{ width: middleWidth, height: middleHeight }}>
            {middleColumnComponent && middleColumnComponent(middleWidth, middleHeight)}
          </div>
        </td>
        <td style={{ width: RIGHT_COLUMN_WIDTH, height }}>
          <div style={{ width: RIGHT_COLUMN_WIDTH, height: height - CHART_TOP_PADDING }}>
            {rightColumnComponent}
          </div>
        </td>
      </tr>
    )
}