import { EAPIForecast, EUnitType } from "./IFetchForecast";

export enum EAxisLabelType { IAxisLabelFixed, IAxisLabelMoving };
export type IAxisLabelFixed = {
    __typename: EAxisLabelType.IAxisLabelFixed,
    min: number;
    max: number;
}
export type IAxisLabelMoving = {
    __typename: EAxisLabelType.IAxisLabelMoving,
    roundTo: number;
}
export type TAxisLabel = IAxisLabelFixed | IAxisLabelMoving;
export interface IParameter {
    label: string;
    apiForecast: EAPIForecast;
    unit: EUnitType;
    liveDataId?: string;
    axisLabel: TAxisLabel;
    measuredDataId?: string;
}
// round to must be divisible by 2 for axis labels to be correct
export const AvailableParameters: { 
    [paramId: string]: IParameter
} = {
    "WindSpeed_kts_10m": {
        label: "Wind Speed 10m", apiForecast: EAPIForecast.ThirtyMin, unit: EUnitType.Knots,
        axisLabel: { __typename: EAxisLabelType.IAxisLabelMoving, roundTo: 10 }
    },
    "GustSpeed_kts_10m": {
        label: "Gust Speed 10m", apiForecast: EAPIForecast.ThirtyMin, unit: EUnitType.Knots,
        axisLabel: { __typename: EAxisLabelType.IAxisLabelMoving, roundTo: 10 }
    },
    "WindDirection_deg_10m": {
        label: "Wind Direction 10m", apiForecast: EAPIForecast.ThirtyMin, unit: EUnitType.BearingDegrees,
        liveDataId: "WindDirection_deg", measuredDataId: "WindDirection_deg",
        axisLabel: { __typename: EAxisLabelType.IAxisLabelFixed, min: 0, max: 360 }
    },
    "BarometricPressure_QNH_mbar": {
        label: "QNH", apiForecast: EAPIForecast.ThirtyMin, unit: EUnitType.MBar,
        liveDataId: "QNH_mbar", measuredDataId: "QNH_mbar",
        axisLabel: { __typename: EAxisLabelType.IAxisLabelMoving, roundTo: 50 }
    },
    "AirTempDryBulb_degC": {
        label: "Air Temp", apiForecast: EAPIForecast.ThirtyMin, unit: EUnitType.DegreesCelcius,
        liveDataId: "AirTemperature_degC", measuredDataId: "AirTemperature_degC",
        axisLabel: { __typename: EAxisLabelType.IAxisLabelMoving, roundTo: 10 }
    },
    "DewPoint_degC": {
        label: "Dew Point", apiForecast: EAPIForecast.ThirtyMin, unit: EUnitType.DegreesCelcius,
        axisLabel: { __typename: EAxisLabelType.IAxisLabelMoving, roundTo: 10 }
    },
    "Hs_m": {
        label: "Hs", apiForecast: EAPIForecast.ThirtyMin, unit: EUnitType.Meters,
        axisLabel: { __typename: EAxisLabelType.IAxisLabelMoving, roundTo: 2 }
    },
    "Heading_deg": {
        label: "Heading", apiForecast: EAPIForecast.Heading, unit: EUnitType.BearingDegrees,
        axisLabel: { __typename: EAxisLabelType.IAxisLabelFixed, min: 0, max: 360 }
    },
    "Total_CurrentDirection_Surface_deg_Mean_BCM": {
        label: "Current Dir", apiForecast: EAPIForecast.ThirtyMin, unit: EUnitType.BearingDegrees,
        liveDataId: "Total_CurrentDirection_Surface_deg_Mean", measuredDataId: "Total_CurrentDirection_Surface_deg_Mean",
        axisLabel: { __typename: EAxisLabelType.IAxisLabelFixed, min: 0, max: 360 }
    },
    "Total_CurrentSpeed_Surface_mps_Mean_BCM": {
        label: "Current Spd", apiForecast: EAPIForecast.ThirtyMin, unit: EUnitType.Mps,
        liveDataId: "Total_CurrentSpeed_Surface_mps_Mean", measuredDataId: "Total_CurrentSpeed_Surface_mps_Mean",
        axisLabel: { __typename: EAxisLabelType.IAxisLabelMoving, roundTo: 1 }
    }
}

export const AvailableParametersArray = Object.keys(AvailableParameters).map(x => {
    return { paramId: x, ...AvailableParameters[x] }
  });