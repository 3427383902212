import React from 'react';
import { IonApp, IonModal, isPlatform } from '@ionic/react';

import { IosInstallModal } from "./pages/IosInstallModal";
import { ProtectedApp } from "./ProtectedApp"
import ISchema from './data/ISchema';

import { AppCacheProvider } from "./contexts/AppCacheContext";
import { AuthProvider } from "./contexts/AuthContext";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';


interface Props {

}

export interface IAuthHeader {
  Authorization: string;
}

interface State {
  authHeader?: IAuthHeader;
  showInstalPrompt?: () => void;
  isIosModalOpen: boolean;
  schema?: ISchema;
  loginError?: string;
}

export default class App extends React.Component<Props, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      isIosModalOpen: false,
    };
  }

  componentDidMount() {
    if (isPlatform('ios') && !isPlatform('pwa')) {
      this.setState({
        showInstalPrompt: () => {
          this.setState({
            isIosModalOpen: true
          })
        }
      })
    }    
  }

  render() {
    return (
      <IonApp>
        <AppCacheProvider>
            <AuthProvider>
              <ProtectedApp 
                showInstalPrompt={this.state.showInstalPrompt}
              />
              <IonModal isOpen={this.state.isIosModalOpen}>
                <IosInstallModal
                  onDismiss={() => { this.setState({ isIosModalOpen: false }) }}
                />
              </IonModal>
            </AuthProvider>
        </AppCacheProvider>  
      </IonApp>    
    );
  }
}