import React, { useContext, useEffect, useState } from "react";
import { AppCacheContext } from "./AppCacheContext";
import { IPdfIndex } from "../data/IPDFData";

export interface IPdfContext {
    pdfIndex?: IPdfIndex;
}

export const PdfContext = React.createContext<IPdfContext>({} as any);
interface Props {
}

const CACHE_KEY = "PdfIndex";
const PDF_INDEX = "/assets/pdfs/index.json";
export const PdfProvider: React.FC<Props> = ({ children }) => {
    const appCache = useContext(AppCacheContext);
    const [pdfIndex, setPdfIndex] = useState<IPdfIndex | undefined>(undefined);

    useEffect(() => {
        const cacheFetchPdfIndex = async (onUpdate: (pdfIndex: IPdfIndex) => void): Promise<IPdfIndex | undefined> => {

            const onUpdateFromCache = async (response: Response): Promise<IPdfIndex | undefined> => {
                const pdfIndex = await response.json() as IPdfIndex;
                for (let i = 0; i < pdfIndex.pdfs.length; i++) {
                    const { src, available } = pdfIndex.pdfs[i];
                    if (available) {
                        const pdfResp = await appCache.cacheOnly(src);
                        if (!pdfResp) {
                            console.warn("Cached PDF not available");
                            return undefined;
                        }
                        pdfIndex.pdfs[i].blob = await pdfResp.blob();
                    }
                }
                return pdfIndex;
            }
    
            const onUpdateFromNetwork = async (response: Response): Promise<IPdfIndex> => {
                const pdfIndex = await response.json() as IPdfIndex;
                for (let i = 0; i < pdfIndex.pdfs.length; i++) {
                    const { src, available } = pdfIndex.pdfs[i];
                    if (available) {
                        const pdfResp = await appCache.networkOnly(src, new Request(src));
                        pdfIndex.pdfs[i].blob = await pdfResp.blob();
                    }
                }
                return pdfIndex;
            }
            
            // cb will always be from net
            const handleUpdateResponse = async (promise: Promise<Response>) => onUpdate(await onUpdateFromNetwork(await promise));
    
            const req = new Request(PDF_INDEX);
            const payload = await appCache.cacheFetch(CACHE_KEY, req, handleUpdateResponse);
    
            if (payload.fromCache) {
                return onUpdateFromCache(payload.response);
            }
            else {
                return onUpdateFromNetwork(payload.response);
            }
        }

        const fetchPdfList = async () => {
            const pdfIndex = await cacheFetchPdfIndex((pdfIndex) => setPdfIndex(pdfIndex));
            if (pdfIndex) {
                setPdfIndex(pdfIndex);
            }
        }
        fetchPdfList();
    }, []);
    
    const value: IPdfContext = {
        pdfIndex
    }    

    return (
        <PdfContext.Provider value={value}>
            {children}
        </PdfContext.Provider>
    )
}