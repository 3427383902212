import React from 'react';
import { DataContext } from '../data/DataContext';
import { DataPageRowLeft } from "../components/DataPageRowLeft";
import { IPageParameters } from './DataPageContent';
import { formatNumberByUnitType, getUnitAsString } from '../Utils';

interface State {
  crntTimestamp: number;
  timerId?: NodeJS.Timeout;
}


interface Props {
  parameter: IPageParameters;
  stationId: string;
}

export class LiveDataRowLeft extends React.Component<Props, State> {
  static contextType = DataContext;
  context!: React.ContextType<typeof DataContext>;

  state: State = {
    crntTimestamp: Date.now()
  }

  componentDidMount() {    
    const timerId = setInterval(() => {
      this.setState({
        crntTimestamp: Date.now()
      });
    }, 10000);
    this.setState({
      timerId
    })
  }

  componentWillUnmount() {
    if (this.state.timerId) {
      clearInterval(this.state.timerId);
    }
  }

  
  render() {
    const { parameter, stationId } = this.props;
    const groupId = parameter.liveDataId ? parameter.liveDataId : parameter.groupId;
    const data = this.context;
    const liveData = data.liveData.Stations[stationId].GroupValues[groupId] ?? [];
    const liveDataValues = liveData.filter(x => x.Primary && (typeof(x.Value) == "number" || typeof(x.Value) == "string"));
    
    let liveDataValue: string;
    if (liveDataValues.length) {
      // there is live data and values for this station:
      if (liveDataValues[0].Timestamp < this.state.crntTimestamp - 60 * 60 * 1000) {
        // Live data is more that 1hr old:
        liveDataValue = '///';
      }
      else {
        // live data is current:
        liveDataValue = formatNumberByUnitType(liveDataValues[0].Value, parameter.unit);
      }
    }
    else if (liveData.length > 0) {
      // there is live data, but no values
      liveDataValue = '///';
    }
    else {
      // no live data for this station
      liveDataValue = '---';
    }

    return (
      <DataPageRowLeft 
          label={parameter.label}
          units={getUnitAsString(parameter.unit)}
          value={liveDataValue}
      />
    );
  }

}
