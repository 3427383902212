import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonSlide, IonSlides, IonTitle, IonToolbar } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

interface Props {
  onDismiss: () => void;
}

export class IosInstallModal extends React.Component<Props> {

  render() {
    const slideOpts = {
      initialSlide: 0,
      speed: 400
    };
    const { onDismiss } = this.props;

    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle>Install in iOS</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={onDismiss}>
                <IonIcon slot="icon-only" icon={closeOutline} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen>
          <IonSlides pager={true} options={slideOpts}>
            <IonSlide>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h1>Step 1</h1>
                <p>Please note that the shellweather.com app for iOS can only be installed from Safari.</p>
                <img
                  style={{ width: '50%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                  src='./assets/install-ios-step1.PNG'
                  alt='iOS install step 1'
                />
                <p>Click the actions button</p>
              </div>
            </IonSlide>
            <IonSlide>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h1>Step 2</h1>
                <p></p>
                <img
                  style={{ width: '50%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                  src='./assets/install-ios-step2.PNG'
                  alt='iOS install step 2'
                />
                <p>Click the <b>Add to Home Screen</b> action</p>
              </div>
            </IonSlide>
            <IonSlide>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <h1>Step 3</h1>
                <p></p>
                <img
                  style={{ width: '50%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                  src='./assets/install-ios-step3.PNG'
                  alt='iOS install step 3'
                />
                <p>Click the <b>Add</b> action</p>
              </div>
            </IonSlide>
          </IonSlides>
        </IonContent>
      </IonPage>
    );
  }
}
