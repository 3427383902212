import React, { useContext } from "react";
import { DataContext } from "../data/DataContext";
import { LiveDataContext, LiveDataProvider } from "./LiveDataContext";
import Splash from "../pages/Splash";
import IDataContextState from "../data/IDataContextState";
import { AuthContext } from "./AuthContext";
import { PdfContext, PdfProvider } from "./PdfContext";
import { ForecastContext, ForecastProvider } from "./ForecastContext";
import { LocalStorageContext, LocalStorageProvider } from "./LocalStorageContext";

interface Props {}
const DataProviderBase: React.FC<Props> = ({ children }) => {
    const liveDataContext = useContext(LiveDataContext);
    const cacheContext = useContext(LocalStorageContext);
    const authContext = useContext(AuthContext);
    const pdfContext = useContext(PdfContext);
    const forecastContext = useContext(ForecastContext);

    if (!liveDataContext.liveData || !pdfContext.pdfIndex || !forecastContext.forecastDataCache) {
        return <Splash message='Loading data...' />;
    }

    const value: IDataContextState = {
        liveData: liveDataContext.liveData,
        cache: cacheContext,
        schema: authContext.schema,
        pdfs: pdfContext.pdfIndex.pdfs,
        forecastDataCache: forecastContext.forecastDataCache,
        immediateFetchStationForecast: forecastContext.immediateFetchStationForecast,
        isStationCached: forecastContext.isStationCached
    }
    return (
        <DataContext.Provider value={value}>
            {children}
        </DataContext.Provider>
    )
}

export const DataProvider: React.FC<Props> = ({ children }) => {

    return (
        <LocalStorageProvider>
            <PdfProvider>
                <ForecastProvider>
                    <LiveDataProvider>
                        <DataProviderBase>
                            {children}
                        </DataProviderBase>
                    </LiveDataProvider>
                </ForecastProvider>
            </PdfProvider>
        </LocalStorageProvider>
    )
}