import React from 'react';
import { IonFooter, IonLabel, IonPage, IonSegment, IonSegmentButton, IonToolbar } from '@ionic/react';
import './DataPage.css';
import { ICachedForecastDataLengths } from '../data/IFetchForecast';
import { DataPageContent, IPageParameters } from "../components/DataPageContent";

interface Props {
  station: ICachedForecastDataLengths;
  stationId: string;
  parameters: IPageParameters[];
};

enum EForecastLength {
  TwentyFourHrs = '24h',
  ThreeDays = '3d',
  SevenDays = '7d'
}

interface State {
  forecastLength: EForecastLength;
}



export default class DataPage extends React.Component<Props, State> {
  state: State = {
    forecastLength: EForecastLength.TwentyFourHrs,
  }

  setForecastLength = (lengthString?: string) => {
    let length: EForecastLength;
    switch (lengthString) {
      case EForecastLength.SevenDays:
        length = EForecastLength.SevenDays;
        break;
      case EForecastLength.ThreeDays:
        length = EForecastLength.ThreeDays;
        break;
      case EForecastLength.TwentyFourHrs:
      default:
        length = EForecastLength.TwentyFourHrs;
        break;
    }
    this.setState({
      forecastLength: length
    })
  }

  
  render() {
    return (
      <IonPage>
        <DataPageContent
          forecastLength={this.state.forecastLength}
          parameters={this.props.parameters}
          station={this.props.station}
          stationId={this.props.stationId}

        />
        <IonFooter>
          <IonToolbar>
            <IonSegment onIonChange={e => this.setForecastLength(e.detail.value)} value={this.state.forecastLength}>
              <IonSegmentButton value={EForecastLength.TwentyFourHrs}>
                <IonLabel>24 hours</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={EForecastLength.ThreeDays}>
                <IonLabel>3 days</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value={EForecastLength.SevenDays}>
                <IonLabel>7 days</IonLabel>
              </IonSegmentButton>
            </IonSegment> 
          </IonToolbar>
        </IonFooter>
      </IonPage>
    );
  }
}