import React from 'react';
import { IonButton, IonLabel, IonSpinner } from '@ionic/react';
import IPdfData from '../data/IPDFData';

// Note: For offline mode, using the cloudfare version does not work:
// Implemented by way of this comment : https://github.com/mozilla/pdf.js/issues/10813#issuecomment-693935886
import { Document, Page, pdfjs } from 'react-pdf';
// @ts-ignore
import workerURL2_5_207  from "./2.5.207.pdf.worker.min.data";
// @ts-ignore
import workerURL2_4_456  from "./2.4.456.pdf.worker.min.data";
if (pdfjs.version === "2.5.207") {
  pdfjs.GlobalWorkerOptions.workerSrc = workerURL2_5_207;
}
else if (pdfjs.version === "2.4.456") {
  pdfjs.GlobalWorkerOptions.workerSrc = workerURL2_4_456;
}
else {
  console.log("PDFJS version is:", pdfjs.version);
  console.log("pdf.js worker not installed for this version");
  pdfjs.GlobalWorkerOptions.workerSrc = workerURL2_4_456;

}
const PDF_OPTIONS_SCALE = 1.0;

interface Props {
  currentPdf?: IPdfData;
  showPdfModal: () => void;
}

interface State {
  numPages: number | null;
  loading: boolean;
  pdfs: IPdfData[];
}

export class PdfViewer extends React.Component<Props, State> {

  state: State = {
    numPages: null,
    loading: true,
    pdfs: []
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.currentPdf !== prevProps.currentPdf) {
      this.setState({
        numPages: null
      });
    }
  }

  onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    this.setState({
      numPages
    });
  }

  render() {
    const { numPages } = this.state;
    const { currentPdf, showPdfModal } = this.props;

    if (!currentPdf) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <p>Please select a PDF</p>
          <IonButton onClick={showPdfModal} expand="block" >
            <IonLabel>
              Select PDF
            </IonLabel>
          </IonButton>
        </div>
      )
    }

    return (
        <Document
          file={currentPdf.blob}
          onLoadSuccess={this.onDocumentLoadSuccess}
          onLoadError={console.error}
          loading={(
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
              <p>Loading PDF</p>
              <IonSpinner />
            </div>
          )}          
          >
          {Array.from(
            new Array(numPages),
            (el, index) => (
              <Page
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  width={window.innerWidth}
                  scale={PDF_OPTIONS_SCALE}
              />
            )
          )}
        </Document>
    );
  }
}