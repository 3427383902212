import { AvailableParameters } from '../data/AvailableParameters';
import { EColorBandType, IPageParameters } from '../components/DataPageContent';

export const AviationParams: IPageParameters[] = [
    { groupId: "WindSpeed_kts_10m", ...AvailableParameters["WindSpeed_kts_10m"], colorBands: { amberStart: 15, redStart: 20, brownStart: 25, type: EColorBandType.GT } },
    { groupId: "GustSpeed_kts_10m", ...AvailableParameters["GustSpeed_kts_10m"], colorBands: { amberStart: 15, redStart: 20, brownStart: 25, type: EColorBandType.GT } },
    { groupId: "WindDirection_deg_10m", ...AvailableParameters["WindDirection_deg_10m"], colorBands: undefined },
    { groupId: "BarometricPressure_QNH_mbar", ...AvailableParameters["BarometricPressure_QNH_mbar"], colorBands: undefined },
    { groupId: "AirTempDryBulb_degC", ...AvailableParameters["AirTempDryBulb_degC"], colorBands: undefined },
    { groupId: "DewPoint_degC", ...AvailableParameters["DewPoint_degC"], colorBands: undefined },
    { groupId: "Hs_m", ...AvailableParameters["Hs_m"], colorBands: { amberStart: 1.5, redStart: 2.0, brownStart: 2.5, type: EColorBandType.GT } },
];